.InputFieldWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 0.6rem;
}

.InputField {
  width: 100%;
}

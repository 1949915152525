@font-face {
  font-family: 'Pokemon'; /*Can be any text*/
  src: local('Ketchum-WeeV'), url('./fonts/Ketchum-WeeV.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  display: flex;
  min-height: 100vh;
  @media (max-width: 480px) {
    min-height: -webkit-fill-available;
  }
  flex-direction: column;
  justify-content: space-between;
  background-color: #303132;

  text-align: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-y: hidden;
  overscroll-behavior: none;
}

.Content {
  width: 80%;
  max-width: 1000px;
}

.Header {
  font-family: 'Pokemon';
  font-size: calc(15px + 2vmin);
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header-img {
  width: 1.8rem;
  height: auto;
  padding-right: 0.4rem;
  transform: rotate(-16deg);
}

.App-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: calc(2vmin);
  margin: 1.5rem;
}

.PokemonName {
  font-size: calc(20px + 2vmin);
  font-weight: bold;
  margin-top: 2.6rem;
}

.PokemonType {
  font-size: calc(15px + 2vmin);
  font-weight: bold;
  margin-bottom: 2rem;
}

.PokemonId {
  font-size: calc(5px + 2vmin);
  margin-bottom: 1rem;
  font-style: italic;
  font-family: monospace;
}

.PrevAndNextPokemon {
  font-size: calc(5px + 2vmin);
  position: relative;
  padding-top: 1px;
}

.PreviousPokemon {
  position: absolute;
  left: 0;
}
.NextPokemon {
  position: absolute;
  right: 0;
}

.SelectButton {
  padding: 0.5rem;
}

.LeftArrow {
  display: flex;
}

.RightArrow {
  transform: rotate(180deg);
  display: flex;
}

.Footer {
  margin-top: auto;
  font-size: calc(1.2vmin);
  width: 80%;
  margin: 1.5rem;
  @media (max-width: 480px) {
    font-size: calc(1.8vmin);
  }
}

@media screen and (min-width: 1600px) {
  .Footer {
    margin-top: auto;
    font-size: small;
    margin: 1.5rem;
  }
}

button,
button:focus button:active {
  color: white;
  outline: none;
  background: transparent;
  border: none;
}
